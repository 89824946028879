.home-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;
    margin: 0 auto;
    height: calc(100vh - 74px);
    box-sizing: border-box;
    max-width: 1400px;
}

.content-section {
    padding: 20px;
}

.header-section h1 {
    color: #010028;
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.highlight {
    color: #FF6D00;
}

.explanation-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.orange-logo {
    display: none;
}

.question-title {
    color: #FF6D00;
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    flex: 1;
}

.description-text {
    color: #333;
    font-size: 1.1rem;
    line-height: 1.6;
    margin: 0;
    flex: 2;
    height: 141px;
}

.user-columns {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-top: 40px;
}

.column {
    width: 30%;
    text-align: center;
}

.column-details {
    background-color: #010028;
    padding: 30px 20px;
    border-radius: 10px;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-icon {
    font-size: 3.3rem;
    color: #FF6D00;
}

.column h3 {
    font-size: 1.5rem;
    margin-bottom: 40px;
    font-weight: bold;
    text-transform: uppercase;
}

.column ul {
    list-style: none;
    padding: 0;
    text-align: left;
}

.column ul li {
    margin-top: 10px;
    padding-left: 25px;
    position: relative;
}

.column ul li:before {
    content: '•';
    color: #FF6D00;
    position: absolute;
    left: 0;
}

.subscription-section {
    margin-top: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
}

.subscription-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 38px;
    width: 100%;
}

.subscription-text h3 {
    font-size: 28px;
    font-weight: 900;
    color: #0A0A3B;
    margin: 5px 0px;
}

.subscription-text p {
    font-size: 1.2rem;
    font-weight: 300;
    margin: 0;
    color: #010028;
    white-space: nowrap;
}

.subscription-input {
    width: 100%;
}

.email-input {
    padding: 10px 15px;
    font-size: 1rem;
    border: 1px solid #010028;
    border-radius: 5px;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    margin: 1.75rem 0;
}

.email-input.invalid {
    animation: shake 0.5s ease;
    border-color: #ff6d6d;
    background-color: #ffecec;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
}

.phone-section {
    background-color: #FF7608;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    position: sticky;
    top: 0;
    text-align: center;
}

.phone-logo {
    width: 37%;
    margin: 40px;
}

.phone-image {
    max-width: 100%;
    margin-bottom: 20px;
    margin-top: 7px;
}

.cta-button-phone {
    background-color: #010028;
    color: #ffffff;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    cursor: pointer;
    margin: 1.75rem 0;
    width: 35%;
}

.cta-button-phone:active {
    background-color: #111166;
    transition: all 0.1s ease-in-out;
}

.mobile-subscription-section {
    width: 70%;
}

.mobile-subscription-section h3 {
    margin: 0px;
}

.mobile-subscription-section p {
    margin-bottom: 0px;
    margin-top: 1.75rem;
}

.questions-section p {
    margin: 0.2px;
}

@media screen and (min-width: 1025px) {
    .mobile-subscription-section {
        display: none;
    }

    .mobile {
        display: none;
    }

    .column-details{
        height: 350px;
    }
}

@media screen and (max-width: 1024px) {
    .home-container {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
    }

    .content-section {
        order: 1;
        padding: 20px;
    }

    .phone-section {
        order: 2;
        margin-top: 1.75rem;
    }

    .header-section h1 {
        font-size: 2.2rem;
    }
    
    .question-title {
        display: none;
    }

    .description-text {
        font-size: 1rem;
    }

    .user-columns {
        flex-direction: column;
        gap: 40px;
    }

    .column {
        width: 100%;
    }

    .phone-logo {
        display: none;
    }

    .phone-image {
        max-width: 100%;
    }

    .subscription-section {
        display: none;
    }

    .mobile.cta-button-phone    {
        margin: 0;
    }

    .desktop {
        display: none;
    }

    .mobile.questions-section {
        margin: 1.5rem 0;
        font-weight: 600;
    }
}

@media screen and (max-width: 600px) {
    .header-section h1 {
        font-size: 1.8rem;
    }

    .question-title {
        font-size: 1.6rem;
    }

    .description-text {
        font-size: 0.9rem;
    }

    .subscription-section input[type="email"] {
        width: 90%;
    }

    .phone-logo {
        display: none;
    }

    .subscription-section {
        display: none;
    }

    .orange-logo {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        display: block;
        z-index: -1;
    }
}