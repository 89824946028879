/* LanguageSwitcher.css */
.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-header {
    color: #FFFFFF;
    font-weight: 600;
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ced4da;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    list-style: none;
    padding: 0;
    margin: 0;
    width: 3rem;
    z-index: 1;
  }
  
  .dropdown-menu li {
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdown-menu li:hover {
    color: #FFFFFF;
    background-color: #010028;
  }