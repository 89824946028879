/* Navbar Container */

.navbar {
    background-color: #010028; /* Dark Blue Background */
    height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px; /* Default padding for general spacing */
    box-sizing: border-box;
  }
  
  /* Navbar Inner Container */
  .nav-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px; /* Padding for mobile view */
  }
  
  /* Apply a max-width only on larger screens */
  @media screen and (min-width: 1024px) {
    .nav-container {
      max-width: calc(100% - 140px); /* Maintain 70px padding on both sides */
      padding: 0; /* Remove internal padding since we're using max-width now */
    }
  }
  
  /* Logo Section */
  .nav-logo {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  
  .logo-img {
    width: 40px; /* Set to an appropriate width */
    height: auto; /* Maintain the aspect ratio */
    margin-right: 8px;
  }
  
  .logo-text {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 1.5rem;
    padding-top: 0.5rem;
  }
  
  /* Hamburger Menu Icon */
  .nav-toggle {
    display: none;
    color: #FFFFFF;
    cursor: pointer;
  }
  
  .menu-icon {
    font-size: 1.5rem;
  }
  
  /* Navigation Menu */
  .nav-menu {
    list-style: none;
    display: flex;
    gap: 40px;
    margin: 0;
    padding: 0;
  }
  
  .nav-item {}
  
  .nav-link {
    color: #FFFFFF;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s;
  }
  
  .nav-link:hover {
    color: #FF6D00; /* Orange accent on hover */
  }
  
  /* User Icon Section */
  .nav-user {
    color: #FFFFFF;
  }
  
  /* Responsive Design for Mobile */
  @media screen and (max-width: 1024px) {
    .nav-toggle {
      display: block;
    }
  
    .nav-menu {
      display: none;
      flex-direction: column;
      gap: 20px;
      background-color: #0A0A3B;
      position: absolute;
      top: 60px;
      right: 0;
      width: 100%;
      text-align: center;
    }
  
    .nav-menu.active {
      display: flex;
    }
  
    .nav-user {
      display: none; /* Hide the user icon in the desktop menu for mobile */
    }
  }